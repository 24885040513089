import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

// material-ui icons
import Menu from '@material-ui/icons/Menu';

// core components
import AdminNavbarLinks from './AdminNavbarLinks';
import Button from 'components/CustomButtons/Button.jsx';

import adminNavbarStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.jsx';

import routes from 'routes';
import ChevronIcon from 'assets/img/ChevronIcon';
import { get } from 'helpers/apiHelpers';
import { OpenInNew } from '@material-ui/icons';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
  },
  bradcrumbsWrapper: {
    paddingLeft: '15px',
    display: 'flex',
    marginLeft: '10px',
    alignItems: 'center',
    fontSize: '1.3em',
    color: '#888888',
  },
  breadrumbsItem: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  chevronIcon: {
    margin: '0 10px 0 10px',
    transform: 'rotate(-90deg)',
    width: '12px',
  },
  link: {
    cursor: 'pointer',
    color: '#888888',
    transition: '.5s',
    '&:hover': { borderBottom: '1px solid #888888' },
  },
  editField: {
    color: 'black',
  },
  breadcrumbInnerContent: {
    display: 'flex',
    gap: '4px',
  },
});
const AdminNavbar = ({
  classes: propClasses,
  rtlActive,
  brandText,
  location,
  handleDrawerToggle,
  breadcrumbAdditionalContent,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [breadcrumbsName, setBreadcrumbsName] = useState(null);
  const [breadcrumbsIcon, setBreadcrumbsIcon] = useState(null);
  const [breadcrumbsPath, setBreadcrumbsPath] = useState(null);
  const [editFieldName, setEditFieldName] = useState(null);
  const [subPageText, setSubPageText] = useState(null);

  useEffect(() => {
    if (location.pathname.includes('/kwg_group')) {
      setBreadcrumbsPath('/admin/meals/kwg_group');
      setSubPageText(t('bradcrumbs.kwgGroup'));
    } else {
      setSubPageText(null);
    }

    setEditFieldName(null);
    setBreadcrumbs();
    if (
      location.pathname.includes('/edit/') ||
      location.pathname.includes('/preview/')
    ) {
      subpageNameSettings();
    }
  }, [location.pathname]);

  const setBreadcrumbs = () => {
    const elementWithoutViews = routes.filter(el => el.path !== undefined);

    if (location.pathname.includes('/admin/meals/edit/')) {
      setBreadcrumbsPath('/admin/meals');
    }

    if (location.pathname.includes('/admin/filter-line-diets/edit/')) {
      setBreadcrumbsPath('/admin/filter-line-diets/');
    }

    elementWithoutViews.map(el => {
      if (location.pathname.includes('/admin/my-links')) {
        setBreadcrumbsName('Skróty');
        setBreadcrumbsIcon(<OpenInNew />);
      }
      if (location.pathname.includes('/admin' + el.path)) {
        setBreadcrumbsIcon(el.icon);
        setBreadcrumbsPath('/admin' + el.path);
        setBreadcrumbsName(null);
      }
      return null;
    });
    const elWithViews = routes.filter(el => el.path === undefined);

    elWithViews.map(el => {
      for (let i = 0; i < el.views.length; i++) {
        if (location.pathname === '/admin' + el.views[i].path) {
          setBreadcrumbsIcon(el.icon);
          setBreadcrumbsName(t(el.name));
          setBreadcrumbsPath('/admin' + el.views[i].path);
        }
      }
      return null;
    });
  };

  const subpageNameSettings = () => {
    const locationPathname = location.pathname;
    const path = locationPathname.split('/')[2]; // get only part of path after /admin/    const id = locationPathname.match(/\d+/g)[0]; // get first number of regex array
    let id = locationPathname.match(/\d+/g)[0]; // get first number of regex array
    if (path === 'bag-containers' || path === 'product-feed') {
      id = locationPathname.split('/')[4];
    }
    if (path === 'kitchen') {
      setEditFieldName('Przepisy do zrobienia');
      return;
    }
    if (path === 'dishes-packhouse') {
      setEditFieldName('Dania do spakowania');
      return;
    }
    if (path === 'bags-packhouse') {
      setEditFieldName('Diety do spakowania');
      return;
    }
    let endpoint = {
      meals: 'dishes',
      types: 'meal-types',
      messages: 'notifications',
      'product-feed': 'feeds',
      'pages-creator': 'pages',
    }[path];
    if (endpoint) {
      if (locationPathname.includes('/kwg_group')) {
        id = locationPathname.split('/')[5];
        endpoint = 'kwg-groups';
      }
      get(`/${endpoint}/${id}`).then(el => {
        if (el.name) {
          setEditFieldName(el.name);
        } else if (el.firstName) {
          setEditFieldName(`${el.firstName} ${el.lastName}`);
        } else if (el.workName) {
          setEditFieldName(el.workName);
        } else if (el.workingName) {
          setEditFieldName(el.workingName);
        } else if (el.code) {
          setEditFieldName(el.code);
        } else if (el.value) {
          setEditFieldName(el.value);
        } else if (el.title) {
          setEditFieldName(el.title);
        } else if (el.content) {
          setEditFieldName('Edycja komunikatu');
        } else if (el.diet) {
          setEditFieldName(el.diet.name);
        } else if (el.label) {
          setEditFieldName(el.label);
        } else if (el.domain) {
          setEditFieldName('Edycja Product Feed');
        } else if (el.nameForClient) {
          setEditFieldName(el.nameForClient);
        }
      });
    }
  };
  return (
    <AppBar className={classNames(propClasses.appBar, propClasses.caterings)}>
      <Toolbar className={propClasses.container}>
        <div className={propClasses.flex}>
          {/* Here we create navbar brand, based on route name */}
          <div
            className={classNames(propClasses.title, classes.bradcrumbsWrapper)}
            color="transparent"
          >
            <span className={classes.flex}>{breadcrumbsIcon}</span>
            <div className={classes.breadrumbsItem}>
              <h3>{breadcrumbsName} </h3>
              {breadcrumbsName && (
                <ChevronIcon className={classes.chevronIcon} />
              )}
              <a className={classes.link} href={breadcrumbsPath}>
                <h3 className={classes.breadcrumbInnerContent}>
                  {brandText} {breadcrumbAdditionalContent}
                </h3>
              </a>
              {subPageText && (
                <>
                  <ChevronIcon className={classes.chevronIcon} />

                  <a className={classes.link} href={breadcrumbsPath}>
                    <h3>{subPageText}</h3>
                  </a>
                </>
              )}
              {editFieldName && <ChevronIcon className={classes.chevronIcon} />}
              <h3 className={classes.editField}>{editFieldName}</h3>
            </div>
          </div>
        </div>
        <Hidden xsDown implementation="css">
          <AdminNavbarLinks rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={propClasses.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

AdminNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  location: PropTypes.object,
  handleDrawerToggle: PropTypes.func,
  breadcrumbAdditionalContent: PropTypes.node,
};

export default withStyles(adminNavbarStyle)(AdminNavbar);
