import NavTabs from 'components/NavTabs/NavTabs';
import { useTranslation } from 'react-i18next';
import MealRoute from './MealRoute';
import GroupOfFinishedProductRoute from './GroupOfFinishedProductRoute';

const Meals = props => {
  const mealsRouter = window.location.pathname.replace('/kwg_group', '');

  const { t } = useTranslation();

  const areTabsDisabled =
    window.location.pathname.includes('/add') ||
    window.location.pathname.includes('/edit');

  return (
    <NavTabs
      disabled={areTabsDisabled}
      tabs={[
        {
          tabButton: t('views.dishes.collectiveSettings'),
          tabContent: <MealRoute props={props} />,
          href: `${mealsRouter}`,
        },
        {
          tabButton: t('views.dishes.groupOfFinishedProducts'),
          tabContent: <GroupOfFinishedProductRoute props={props} />,
          href: `${window.location.pathname}/kwg_group`,
        },
      ]}
    />
  );
};

export default Meals;
