import { Router, Route, Switch } from 'react-router';

import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_KWG_GROUP,
  ROLE_SHOW_KWG_GROUP,
  ROLE_EDIT_KWG_GROUP,
} from 'helpers/roles';
import GroupOfFinishedProductsList from 'views/MealsNew/GroupOfFinishedProducts/List/GroupOfFinishedProductsList';
import GroupOfFinishedProductsForm from 'views/MealsNew/GroupOfFinishedProducts/Form/GroupOfFinishedProductsForm';

const GroupOfFinishedProductRoute = ({ props }) => {
  const ListSecured = withRole(
    ROLE_SHOW_KWG_GROUP,
    GroupOfFinishedProductsList
  );

  const CreateForm = withRole(
    ROLE_CREATE_KWG_GROUP,
    GroupOfFinishedProductsForm
  );
  const EditForm = withRole(ROLE_EDIT_KWG_GROUP, GroupOfFinishedProductsForm);

  return (
    <Router history={props.history}>
      <Switch>
        <Route
          exact
          path={`${props.match.url}/kwg_group`}
          render={parentProps => <ListSecured {...props} {...parentProps} />}
        />
        <Route
          path={`${props.match.url}/kwg_group/add`}
          render={() => <CreateForm {...props} />}
        />
        <Route
          path={`${props.match.url}/kwg_group/edit/:id`}
          render={parentProps => {
            return <EditForm {...props} {...parentProps} />;
          }}
        />
      </Switch>
    </Router>
  );
};

export default GroupOfFinishedProductRoute;
