import { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withToast } from 'material-ui-toast-redux';
import { withTranslation } from 'react-i18next';

import { fetchRoles } from 'actions/Roles';
import { fetchUser, clearUser } from 'actions/Employees';
import { fetchBrandsList, fetchBrands } from 'actions/Brands';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { ReactPhoneNumberAdapter } from 'components/ReactPhoneNumberAdapter/ReactPhoneNumberAdapter';

import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

import { post, put } from 'helpers/apiHelpers';
import { errorNotificationMapObject } from 'helpers/helpers';
import { Check, Info } from '@material-ui/icons';
import { Tooltip, Checkbox, FormControlLabel } from '@material-ui/core';

import userPhotoTemplate from 'assets/img/new_logo.png';

import defaultState from './defaultState';
import EmployeeWorkHistory from '../EmployeeWorkHistory/EmployeeWorkHistory';
import EmployeeActivityHistory from '../EmployeeActivityHistory/EmployeeActivityHistory';

class GeneralInfo extends Component {
  state = {
    ...defaultState,
    passwordError: '',
    emailTaken: null,
    loading: false,
  };
  blockedCharPattern = /[\^&\*\(\)_\+\-=\[\]\{};':"\\|,.<>\/\?`~!]/;
  userId = this.props.match.params.id;
  isEdit = this.props.location.pathname.includes('edit');

  componentDidMount = async () => {
    this.props.fetchBrandsList();
    this.props.fetchRoles();
    let user = { ...defaultState };
    if (this.userId) {
      const givenUser = await this.props.fetchUser(this.userId);

      user = {
        firstName: givenUser.firstName,
        lastName: givenUser.lastName,
        phone: givenUser.phone,
        email: givenUser.email,
        hourlySalary: givenUser.hourlySalary ?? 0.0,
        productionWorker: givenUser.productionWorker ?? false,
        selectedBrands: givenUser.brands.map(brand => brand['@id']),
        selectedRoles: givenUser.permissions.map(
          permission => permission['@id']
        ),
        image: givenUser.image?.['@id'],
        imageUrl: givenUser.image?.contentUrl,
        limitationSettingsManagement:
          givenUser?.limitationSettingsManagement || false,
      };
    }

    this.setState(user);
  };

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handlePhoneChange = (number, countryCode) => {
    this.setState(prevState => ({
      ...prevState,
      phone: {
        ...prevState.phone,
        number,
        countryCode,
      },
    }));
  };

  validateEmail = () => {
    return this.state.email.includes('@');
  };

  validatePassword = () => {
    return this.state.plainPasswordMatch === this.state.plainPassword;
  };

  validateForm = () => {
    if (
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.phone.number ||
      !this.state.email
    ) {
      this.props.openToast({
        messages: [
          this.props.t(
            'errors.fillAllRequiredFields',
            'Wypelnij wszystkie obowiązkowe pola oznaczone gwiazdką'
          ),
        ],

        type: 'error',
        autoHideDuration: 3000,
      });

      return false;
    }

    if (!this.validateEmail()) {
      this.props.openToast({
        messages: [
          this.props.t(
            'myCompany.toast.incorrectEmail',
            'Nieprawidłowy adres email'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });

      return false;
    }

    if (!this.validatePassword()) {
      this.props.openToast({
        messages: [
          this.props.t('errors.passwordsDontMatch', 'Hasła nie są takie same'),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });

      return false;
    }

    return true;
  };

  getImage = (stateName, data) => {
    this.setState({
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    });
  };

  removeImage = stateName => {
    this.setState({
      [stateName]: null,
      [`${stateName}Url`]: null,
    });
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return;
    }
    this.setState({
      loading: true,
    });

    let data = {
      type: 'EMPLOYEE',
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      email: this.state.email.toLowerCase(),
      plainPassword:
        this.state.plainPassword !== '' ? this.state.plainPassword : null,
      brands: this.state.selectedBrands,
      image: this.state.image,
      permissions: this.state.selectedRoles,
      productionWorker: this.state.productionWorker,
      hourlySalary: parseFloat(
        parseFloat(this.state.hourlySalary ?? 0).toFixed(2)
      ),
      limitationSettingsManagement: this.state.limitationSettingsManagement,
    };

    if (!this.isEdit) {
      data.language = this.props.adminDefaultLanguage ?? 'pl';
      data.isEmailNotifyEnabled = this.state.isEmailNotifyEnabled ?? true;
    }

    const action = this.isEdit
      ? put(`/employees/${this.userId}`, data)
      : post('/employees', data);

    action.then(
      () => {
        this.setState({
          loading: false,
        });
        this.props.history.push('/admin/employees');
        window.location.reload();
      },
      error => {
        const { propertyPath } = error.response.data.violations[0];
        if (
          propertyPath === 'email' ||
          propertyPath === 'plainPassword' ||
          propertyPath === 'phone'
        ) {
          this.setState({
            loading: false,
          });

          const messages = error.response.data.violations.map(
            ({ propertyPath, message }) => {
              const prefix = errorNotificationMapObject[propertyPath];
              return `${prefix}: ${message}`;
            }
          );

          return this.props.openToast({
            messages,
            type: 'error',
            autoHideDuration: 3000,
          });
        } else {
          this.setState({
            loading: false,
          });

          return this.props.openToast({
            messages: [this.props.t('employees.smthWrong')],
            type: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    );
  };

  render() {
    const { classes, t } = this.props;
    const {
      email,
      phone,
      lastName,
      firstName,
      hourlySalary,
      plainPassword,
      selectedRoles,
      selectedBrands,
      productionWorker,
      plainPasswordMatch,
      isEmailNotifyEnabled,
      limitationSettingsManagement,
    } = this.state;

    return (
      <form>
        <h2>{this.userId ? t('employees.edit') : t('employees.create')} </h2>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem sm={6}>
                <FormTextInput
                  label={t('employees.firstName') + '*'}
                  classes={classes}
                  name="firstName"
                  value={firstName}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                  maxLength={64}
                />
                <FormTextInput
                  label={t('employees.lastName') + '*'}
                  classes={classes}
                  name="lastName"
                  value={lastName}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                  maxLength={64}
                />
                <ReactPhoneNumberAdapter
                  label={t('employees.phone') + '*'}
                  name="phone"
                  value={phone}
                  onChange={this.handlePhoneChange}
                />
                <SelectInput
                  classes={classes}
                  multiple={true}
                  label={t('employees.role')}
                  mapBy="name"
                  trackBy="@id"
                  name="selectedRoles"
                  value={selectedRoles}
                  options={this.props.roles}
                  handleChange={this.handleInputChange}
                  id="roles"
                  size={12}
                />
                <SelectInput
                  classes={classes}
                  multiple={true}
                  label={t('employees.brand')}
                  options={this.props.brands}
                  value={selectedBrands}
                  mapBy="name"
                  trackBy="@id"
                  name="selectedBrands"
                  handleChange={this.handleInputChange}
                  id="brands"
                  size={12}
                />
                <FormTextInput
                  label={t('employees.emailA') + '*'}
                  classes={classes}
                  name="email"
                  value={email}
                  noAutoComplete={true}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  label={t('employees.hourlySalary', '$$Stawka godzinowa')}
                  classes={classes}
                  name="hourlySalary"
                  type="number"
                  value={hourlySalary}
                  noAutoComplete={true}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  label={
                    this.isEdit ? t('employees.newPass') : t('employees.pass')
                  }
                  classes={classes}
                  name="plainPassword"
                  value={plainPassword}
                  type="password"
                  noAutoComplete={true}
                  handleChange={e => {
                    !this.blockedCharPattern.test(e.target.value) &&
                      this.handleInputChange(e);
                  }}
                  inputSize={12}
                />
                <FormTextInput
                  label={
                    this.isEdit ? t('employees.rNewpass') : t('employees.rpass')
                  }
                  classes={classes}
                  name="plainPasswordMatch"
                  value={plainPasswordMatch}
                  success={
                    this.state.plainPassword !== '' &&
                    this.state.plainPassword === this.state.plainPasswordMatch
                  }
                  error={
                    !(
                      this.state.plainPassword === this.state.plainPasswordMatch
                    )
                  }
                  type="password"
                  noAutoComplete={true}
                  handleChange={e => {
                    !this.blockedCharPattern.test(e.target.value) &&
                      this.handleInputChange(e);
                  }}
                  inputSize={12}
                />
                <GridItem
                  sm={12}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        name="limitationSettingsManagement"
                        onClick={() =>
                          this.setState({
                            limitationSettingsManagement:
                              !limitationSettingsManagement,
                          })
                        }
                        checked={limitationSettingsManagement}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={t(
                      'employees.limitationOrderMenagment',
                      'Ograniczenie zarządzania zamówieniami'
                    )}
                  />
                  <Tooltip
                    title={
                      <h4>
                        {t(
                          'employees.limitationOrderMenagment.tooltip',
                          'Włączając to ustawienie pracownik będzie mógł zarządzać zamówieniami w takim samym zakresie, jak klient (tj. w ramach tych samych ograniczeń).'
                        )}
                      </h4>
                    }
                    placement="top"
                  >
                    <Info
                      fontSize="small"
                      style={{
                        marginLeft: '-10px',
                        color: 'grey',
                        display: 'block',
                      }}
                    />
                  </Tooltip>
                </GridItem>
                <GridItem sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        name="productionWorker"
                        onClick={() =>
                          this.setState({ productionWorker: !productionWorker })
                        }
                        checked={productionWorker}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={t(
                      'employees.productionWorker',
                      '$$Pracownik produkcyjny'
                    )}
                  />
                </GridItem>
                {!this.isEdit && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isEmailNotifyEnabled}
                        onChange={() =>
                          this.setState({
                            isEmailNotifyEnabled: !isEmailNotifyEnabled,
                          })
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={
                      <div style={{ display: 'flex' }}>
                        <span>
                          {t(
                            'employees.isEmailNotifyEnabled',
                            '$$Wyślij powiadomienie do pracownika'
                          )}
                        </span>
                        <div style={{ marginLeft: '10px' }}>
                          <Tooltip
                            title={
                              <div>
                                <h4>
                                  {t(
                                    'employees.isEmailNotifyEnabled.tooltip1',
                                    '$$Powiadomienie będzie wysłane po spełnieniu poniższych warunków:'
                                  )}
                                </h4>
                                <h4>
                                  {t(
                                    'employees.isEmailNotifyEnabled.tooltip2',
                                    '$$1. Włączenia powiadomienia mailowego w ustawieniach powiadomień'
                                  )}
                                </h4>
                                <h4>
                                  {t(
                                    'employees.isEmailNotifyEnabled.tooltip3',
                                    '$$2. Poprawnej konfiguracji mailera w konfiguracji marki'
                                  )}
                                </h4>
                              </div>
                            }
                            placement="right"
                          >
                            <Info
                              fontSize="small"
                              style={{
                                color: 'grey',
                                display: 'block',
                              }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    }
                  />
                )}
              </GridItem>
              <GridItem md={6}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '20px' }}
                >
                  Zdjęcie:
                </FormLabel>
                <FormImageUpload
                  classes={classes}
                  stateName="image"
                  getImage={this.getImage}
                  removeImage={this.removeImage}
                  previewUrl={this.state.imageUrl}
                  defaultImage={userPhotoTemplate}
                />
              </GridItem>
              {this.state.loading ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <CircularProgress />
                </div>
              ) : (
                <FormControlButtons
                  classes={classes}
                  loading={this.state.loading}
                  discardText={t('common.shared.cancel')}
                  submitText={t('common.shared.save')}
                  cancelPath="/admin/employees"
                  handleSubmit={this.handleSubmit}
                />
              )}
            </GridContainer>
          </CardBody>
        </Card>
        {this.isEdit && (
          <>
            <EmployeeWorkHistory userId={this.userId} classes={classes} />
            <EmployeeActivityHistory
              classes={classes}
              match={this.props.match}
            />
          </>
        )}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  user: state.Employees.user,
  brands: state.Brands.brandsList,
  roles: state.Roles.roles,
  adminDefaultLanguage: state.Brands.brand.multinational.adminDefaultLanguage,
});

const mapDispatchToProps = dispatch => ({
  fetchUser: id => dispatch(fetchUser(id)),
  clearUser: () => dispatch(clearUser()),
  fetchBrandsList: () => dispatch(fetchBrandsList()),
  fetchBrands: () => dispatch(fetchBrands()),
  fetchRoles: () => dispatch(fetchRoles()),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withTranslation(),
  withToast
);

export default enhance(GeneralInfo);
