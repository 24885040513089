const HELP_ARTICLE_URL = 'https://pomoc.caterings.pl/article';

const EXTERNAL_URLS = {
  howDeliveryDiscountWorks: `${HELP_ARTICLE_URL}/137-jak-dziala-rabat-za-wspolna-dostawe`,
  invoiceConfig: `${HELP_ARTICLE_URL}/187-konfiguracja-fakturowni`,
  payUPaymentConfig: `${HELP_ARTICLE_URL}/132-konfiguracja-sklepu-payu`,
  waysOfPackingBags: `${HELP_ARTICLE_URL}/80-sposoby-pakowania-toreb-z-caterings`,
};

export default EXTERNAL_URLS;
