import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';

import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';

import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import { ReactPhoneNumberAdapter } from 'components/ReactPhoneNumberAdapter/ReactPhoneNumberAdapter';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import {
  fetchBrand,
  fetchBrands,
  createBrand,
  updateBrand,
} from 'actions/Brands';

import { get, post, put } from 'helpers/apiHelpers';
import { addUserBrand, handleBrandChange } from 'actions/Auth';
import withErrorHandler from 'components/HOC/withErrorHandler';
import SelectInput from 'components/FormSelect/SelectInput';
import { Alert } from '@mui/material';
import { withRouter } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from 'components/CustomButtons/Button';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import CardWrapper from 'components/Card/CardWrapper';
import useGetSalesMarketLang from 'hooks/redux/Brands/useGetSalesMarketLang';
import { modulesDefaultState } from './consts';

const dayFields = [
  'newOrder',
  'newOrderSubscription',
  'changeDate',
  'changeAddress',
  'changeDiet',
  'buyAddons',
  'paymentDeadline',
  'shouldBeDeliveredIn',
  'shouldBeDeliveredInBlock',
];

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const dayObject = (curr = {}) => {
  let obj = {};

  dayFields.forEach(field => {
    obj[field] = 0;
  });

  return { ...obj, ...curr };
};

const useStyles = makeStyles(() => ({
  dialogContent: { minHeight: '100px' },
  dialogContentParagraph: { fontSize: '14px', marginBottom: '30px' },
  dialogContentInputDisabled: {
    background: 'RGB(224,224,224)',
    color: 'RGB(130,130,130)',
  },
  dialogActions: { display: 'flex', justifyContent: 'end' },
}));
const BrandInformation = ({
  classes: propClasses,
  t,
  history,
  selectedBrand,
  fetchBrand,
  createBrand,
  updateBrand,
  fetchBrands,
  openToast,
  isEdit,
  addUserBrand,
  errorHandlerCatch,
  handleBrandChange,
  errors,
  errorHandlerResetState,
}) => {
  const salesMarketLang = useGetSalesMarketLang();

  const classes = useStyles();
  const [domain, setDomain] = useState([]);
  const [domainToChange, setDomainToChange] = useState({
    oldDomain: '',
    newDomain: '',
  });
  const [isOpen, setIsOpen] = useState(false);

  const [brands, setBrands] = useState([]);
  const [brandConfig, setBrandConfig] = useState({
    name: '',
    shortName: '',
    mainDomain: '',
    email: '',
    phone: '',
    marketingTermsContent: '',
    termsContent: '',
    showCalculatorLink: false,
    calculatorLink: '',
  });
  const [copyConfig, setCopyConfig] = useState({
    fromBrand: null,
    offer: true,
    dishes: true,
    zones: true,
    deadlines: true,
  });

  const [multinational, setMultinational] = useState({
    salesMarket: salesMarketLang,
  });

  const [foodsiConfiguration, setFoodsiConfiguration] = useState(
    modulesDefaultState.Foodsi
  );

  useEffect(() => {
    (async () => {
      const brandsResponse = await fetchBrands();
      setBrands(brandsResponse);
    })();
    if (isEdit) {
      (async () => {
        const brandConfig = await fetchBrand(selectedBrand);

        setBrandConfig({
          name: brandConfig.name,
          shortName: brandConfig.shortName,
          mainDomain: brandConfig.mainDomain,
          email: brandConfig.email,
          phone: brandConfig.phone,
          marketingTermsContent: brandConfig.marketingTermsContent,
          termsContent: brandConfig.termsContent,
          showCalculatorLink: brandConfig.showCalculatorLink,
          calculatorLink: brandConfig.calculatorLink,
        });

        setMultinational(brandConfig.multinational);
      })();
    }
    get('/panel-domains').then(response => {
      const domains = response.domains.map((domain, index) => ({
        key: index,
        label: domain,
        value: domain,
      }));
      setDomain(domains);
    });
    get(`brands/${selectedBrand}/modules`, {
      'module[]': 'Foodsi',
    }).then(response => {
      setFoodsiConfiguration(response.configuration.Foodsi);
    });
  }, [isEdit, fetchBrand, selectedBrand, fetchBrands]);

  const handleInputChange = e => {
    setBrandConfig(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePhoneChange = (number, countryCode) => {
    setBrandConfig(prevState => ({
      ...prevState,
      phone: {
        ...prevState.phone,
        number,
        countryCode,
      },
    }));
  };

  const brandAction = data => {
    return isEdit ? updateBrand(selectedBrand, data) : createBrand(data);
  };

  const foodsiAction = () => {
    return put(`/brands/${selectedBrand}/modules`, {
      module: 'Foodsi',
      configuration: {
        Foodsi: foodsiConfiguration,
      },
    });
  };

  const handleSubmit = async () => {
    if (isEmpty(brandConfig.termsContent)) {
      return openToast({
        messages: [
          t('brandCfg.error.serviceTerms.emptyField', {
            defaultValue: '$$Pole" {{ label1 }}" powinno być uzupełnione.',
            replace: {
              label1: t('brandCfg.contentReg'),
            },
          }),
        ],
        type: 'error',
      });
    }

    const data = {
      name: brandConfig.name,
      shortName: brandConfig.shortName,
      mainDomain: brandConfig.mainDomain,
      email: brandConfig.email,
      phone: brandConfig.phone,
      marketingTermsContent: brandConfig.marketingTermsContent,
      termsContent: brandConfig.termsContent,
      showCalculatorLink: brandConfig.showCalculatorLink,
      calculatorLink: brandConfig.calculatorLink,
      multinational: multinational,
      copy:
        copyConfig.fromBrand?.['@id'] ?? copyConfig.fromBrand
          ? {
              fromBrand: copyConfig.fromBrand['@id'] ?? copyConfig.fromBrand,
              offer: copyConfig.offer,
              dishes: copyConfig.dishes && copyConfig.offer,
              zones: copyConfig.zones,
              deadlines: copyConfig.deadlines,
            }
          : null,
    };

    if (!isEdit) {
      days.forEach(field => {
        data[field] = dayObject();
      });

      data.adminDays = [];
      data.days = [];
      data.daysDefault = null;
      data.daysMax = null;
      data.daysMin = null;
      data.daysSelectorType = 'RANGE';
    }

    try {
      errorHandlerResetState();

      const [brandResponse] = await Promise.all([
        brandAction(data),
        foodsiAction(),
      ]);

      // TODO: translate
      if (!isEdit) {
        openToast({
          messages: [
            'Marka została utworzona.',
            'Pamiętaj o dodaniu uprawnień do marki odpowiednim pracownikom.',
          ],
          type: 'success',
          autoHideDuration: 3000,
        });
        handleBrandChange(null, { brand: brandResponse, refresh: false });
        history.push(
          `/admin/client-panel-design?selectedBrand=${brandResponse.id}`
        );
      } else {
        openToast({
          messages: [t('success.changesSaved')],
          type: 'success',
          autoHideDuration: 3000,
        });
      }

      await addUserBrand({
        '@id': brandResponse['@id'],
        id: brandResponse.id,
        name: brandResponse.name,
      });

      // !isEdit && this.props.history.push('/admin/company');
    } catch (error) {
      errorHandlerCatch(error);
    }
  };

  const saveNewLinkDomain = () => {
    post('/panel-domains-update', {
      oldDomain: domainToChange.oldDomain,
      newDomain: domainToChange.newDomain,
    })
      .then(res => {
        openToast({
          messages: [
            t(
              'success.changesSaved.changedDomain',
              'Żądanie zmian zostało zlecone. Wprowadzenie zmian może potrwać kilka minut'
            ),
          ],
          type: 'success',
          autoHideDuration: 3000,
        });
      })
      .catch(err => {
        openToast({
          messages: [t('error.changesSaved.changedDomain')],
          type: 'error',
          autoHideDuration: 3000,
        });
      })
      .finally(() => {
        setIsOpen(false);
      });
  };

  const handleSalesMarketChange = salesMarket => {
    setMultinational(prevState => ({
      ...prevState,
      salesMarket,
    }));
  };

  const toggleEnabled = () => {
    setFoodsiConfiguration(prev => ({ ...prev, enabled: !prev.enabled }));
  };

  useEffect(() => {
    handleSalesMarketChange(salesMarketLang);
  }, [salesMarketLang]);

  return (
    <>
      <CardWrapper title={t('brandCfg.basicInformations')}>
        <CardBody>
          <GridContainer>
            <GridItem md={6}>
              <FormTextInput
                label={t('brandCfg.brandName') + '*'}
                classes={propClasses}
                name="name"
                value={brandConfig.name}
                handleChange={handleInputChange}
                inputSize={12}
                errors={errors}
              />
              <FormTextInput
                label={t('brandCfg.mainDomain')}
                classes={propClasses}
                name="mainDomain"
                value={brandConfig.mainDomain}
                maxLength={64}
                handleChange={handleInputChange}
                inputSize={12}
              />
              <SelectInput
                label={t(
                  'brandCfg.mainDomain.impresonationMode',
                  'Zmiana domeny trybu podszywania'
                )}
                classes={propClasses}
                mapBy="label"
                trackBy="label"
                value={domainToChange.oldDomain}
                options={domain}
                handleChange={(ev, obj) => {
                  setDomainToChange(prev => ({
                    ...prev,
                    oldDomain: obj.value,
                    ...(domainToChange.newDomain === '' && {
                      newDomain: brandConfig.mainDomain,
                    }),
                  }));
                  setIsOpen(true);
                }}
                size={12}
              />

              <FormTextInput
                label={t('brandCfg.short')}
                classes={propClasses}
                name="shortName"
                value={brandConfig.shortName}
                handleChange={handleInputChange}
                inputSize={12}
              />
            </GridItem>
            <GridItem md={6}>
              <FormTextInput
                label={t('brandCfg.emailAddr')}
                classes={propClasses}
                name="email"
                value={brandConfig.email}
                handleChange={handleInputChange}
                inputSize={12}
                errors={errors}
              />
              <ReactPhoneNumberAdapter
                label={t('brandCfg.infolineClient')}
                name="phone"
                value={brandConfig.phone}
                onChange={handlePhoneChange}
                inputSize={12}
              />
              <FormTextInput
                label={t('brandCfg.marketingContent')}
                classes={propClasses}
                name="marketingTermsContent"
                value={brandConfig.marketingTermsContent}
                handleChange={handleInputChange}
                inputSize={12}
                multiline={true}
                rows={2}
                rowsMax={20}
                maxLength={4000}
              />
              <FormTextInput
                label={t('brandCfg.contentReg')}
                classes={propClasses}
                name="termsContent"
                value={brandConfig.termsContent}
                handleChange={handleInputChange}
                inputSize={12}
                multiline={true}
                rows={2}
                rowsMax={20}
                maxLength={4000}
              />
            </GridItem>
          </GridContainer>
        </CardBody>

        {!isEdit && (
          <CardBody>
            <GridContainer>
              <GridItem md={12}>
                <h1>{t('brandCfg.copy', 'Kopiuj ustawienia z innej marki')}</h1>
              </GridItem>
              <GridItem md={12}>
                <SelectInput
                  label={t('brandCfg.selectBrand', 'Wybierz markę')}
                  classes={propClasses}
                  mapBy="name"
                  trackBy="@id"
                  value={copyConfig.fromBrand}
                  options={brands}
                  handleChange={(ev, obj) => {
                    setCopyConfig(prevState => ({
                      ...prevState,
                      fromBrand:
                        copyConfig.fromBrand === obj['@id'] ? null : obj['@id'],
                    }));
                  }}
                  size={12}
                />
              </GridItem>
              {copyConfig.fromBrand && (
                <>
                  <GridItem md={12}>
                    <Alert severity="info">
                      {t(
                        'brandCfg.copy.info',
                        'Kopiowanie może zająć kilka minut, w zależności od ilości danych może być to o ~3min do kilku godzin w przypadku rozbudowanej ofery oraz dużej ilości dań do skopiowania, postaraj się w tym czasie nie pracować na ustawieniach marki oraz kopiowanych elementach.'
                      )}
                    </Alert>
                  </GridItem>
                  <GridItem md={copyConfig.offer ? 2 : 12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={copyConfig.offer}
                          onChange={() => {
                            setCopyConfig(prevState => ({
                              ...prevState,
                              offer: !copyConfig.offer,
                            }));
                          }}
                          checkedIcon={
                            <Check className={propClasses.checkedIcon} />
                          }
                          icon={<Check className={propClasses.uncheckedIcon} />}
                          classes={{
                            checked: propClasses.checked,
                            root: propClasses.checkRoot,
                          }}
                        />
                      }
                      label={t('brandCfg.copy.offer', 'Kopiuj ofertę firmy')}
                    />
                  </GridItem>
                  {copyConfig.offer && (
                    <GridItem md={10}>
                      <Alert severity="info">
                        {t(
                          'brandCfg.copy.offer.info',
                          'Skopiowane zostaną: Wielkości, Typy posiłków, Warianty, Diety, Pakiety, Kaloryczności, Cenniki diet'
                        )}
                      </Alert>
                    </GridItem>
                  )}
                  <GridItem md={copyConfig.dishes && copyConfig.offer ? 2 : 12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!copyConfig.offer}
                          checked={copyConfig.dishes && copyConfig.offer}
                          onChange={() => {
                            setCopyConfig(prevState => ({
                              ...prevState,
                              dishes: !copyConfig.dishes,
                            }));
                          }}
                          checkedIcon={
                            <Check className={propClasses.checkedIcon} />
                          }
                          icon={<Check className={propClasses.uncheckedIcon} />}
                          classes={{
                            checked: propClasses.checked,
                            root: propClasses.checkRoot,
                          }}
                        />
                      }
                      label={t(
                        'brandCfg.copy.dishes',
                        'Kopiuj dania (kopiowanie oferty firmy musi być zaznaczone)'
                      )}
                    />
                  </GridItem>
                  {copyConfig.dishes && copyConfig.offer && (
                    <GridItem md={10}>
                      <Alert severity="info">
                        {t(
                          'brandCfg.copy.offer.info2',
                          'Skopiowane zostaną: Ustawienia wielkości dań, zachowując ilości składników oraz przepisów z źródłowej marki'
                        )}
                      </Alert>
                    </GridItem>
                  )}
                  <GridItem md={copyConfig.deadlines ? 2 : 12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={copyConfig.deadlines}
                          onChange={() => {
                            setCopyConfig(prevState => ({
                              ...prevState,
                              deadlines: !copyConfig.deadlines,
                            }));
                          }}
                          checkedIcon={
                            <Check className={propClasses.checkedIcon} />
                          }
                          icon={<Check className={propClasses.uncheckedIcon} />}
                          classes={{
                            checked: propClasses.checked,
                            root: propClasses.checkRoot,
                          }}
                        />
                      }
                      label={t(
                        'brandCfg.copy.deadlines',
                        'Kopiuj ramy czasowe'
                      )}
                    />
                  </GridItem>
                  {copyConfig.deadlines && (
                    <GridItem md={10}>
                      <Alert severity="info">
                        {t(
                          'brandCfg.copy.deadlines.info',
                          'Skopiowane zostaną: Ramy czasowe, takie jak składanie zamówień'
                        )}
                      </Alert>
                    </GridItem>
                  )}
                </>
              )}
            </GridContainer>
          </CardBody>
        )}
      </CardWrapper>

      <CardWrapper title={t('brandCfg.additionalSettings')}>
        <CardBody>
          <GridContainer>
            <GridItem md={6}>
              <SelectInput
                label={t('brandCfg.additionalSettings.salesMarket')}
                classes={propClasses}
                mapBy="label"
                trackBy="value"
                name="salesMarket"
                value={multinational.salesMarket}
                options={[
                  {
                    label: t(
                      'brandCfg.additionalSettings.salesMarket.poland',
                      'Polska'
                    ),
                    value: 'pl',
                  },
                  {
                    label: t(
                      'brandCfg.additionalSettings.salesMarket.czechRepublic',
                      'Czechy'
                    ),
                    value: 'cs',
                  },
                ]}
                handleChange={({ target: { value } }) => {
                  handleSalesMarketChange(value);
                }}
                size={12}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={foodsiConfiguration.enabled}
                    onClick={toggleEnabled}
                    checkedIcon={<Check className={propClasses.checkedIcon} />}
                    icon={<Check className={propClasses.uncheckedIcon} />}
                    classes={{
                      checked: propClasses.checked,
                      root: propClasses.checkRoot,
                    }}
                  />
                }
                label={t('brandCfg.additionalSettings.foodsi', 'Marka foodsi')}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>

      <FormControlButtons
        classes={propClasses}
        submitText={t('common.shared.save')}
        handleSubmit={handleSubmit}
      />

      <Dialog
        maxWidth="sm"
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <DialogTitle className={propClasses.modalHeader}>
          {t(
            'brandCfg.impresonationMode.modalTitle',
            'Zmień domenę trybu podszywania'
          )}
        </DialogTitle>
        <DialogContent
          className={classNames(propClasses.modalBody, classes.dialogContent)}
        >
          <p className={classes.dialogContentParagraph}>
            {t(
              'brandCfg.impresonationMode.modalDescription',
              'UWAGA! Po zapisaniu zmian dokonanych w obrębie trybu podszywania, przywrócenie ustawień domyślnych nie będzie możliwe. Podczas edycji pola "Zmiana domeny trybu podszywania" zalecany jest kontakt z pracownikiem supportu Caterings.'
            )}
          </p>

          <FormTextInput
            className={classes.dialogContentInputDisabled}
            label={t('brandCfg.oldDomain', 'Dotychczasowa domena')}
            classes={propClasses}
            name="oldDomain"
            value={domainToChange.oldDomain}
            disabled={true}
            handleChange={() => {}}
            inputSize={12}
            errors={errors}
          />

          <FormTextInput
            label={t('brandCfg.newDomain', 'Nazwa nowej domeny')}
            classes={classes}
            name="newDomain"
            value={domainToChange.newDomain}
            handleChange={e => {
              setDomainToChange(prev => ({
                ...prev,
                newDomain: e.target.value,
              }));
            }}
            inputSize={12}
            errors={errors}
          />
        </DialogContent>
        <DialogActions
          className={classNames(propClasses.modalFooter, classes.dialogActions)}
        >
          <Button
            color="danger"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {t('common.cancel', 'Anuluj')}
          </Button>
          <Button onClick={saveNewLinkDomain} color="success">
            {t('common.save', 'Zapisz')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand } }) => ({
      selectedBrand,
    }),
    {
      fetchBrand,
      createBrand,
      updateBrand,
      fetchBrands,
      addUserBrand,
      handleBrandChange,
    }
  ),
  withToast,
  withRouter,
  withStyles(combinedStyles),
  withErrorHandler
);

export default enhance(BrandInformation);
